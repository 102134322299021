import React from "react";

const Heading = ({
  heading = "",
  fontSize,
  fontWeight,
  animationDelay = 0.2,
  className = "",
  onClick,
  wordBaseAnimation = false,
  useSecondaryFont = false,
  useDarkColorFont = false,
  animationSpeed = "normal",
  gapBetweenChars,
  justifyContent,
}) => {
  heading =
    (heading.includes(" ") && wordBaseAnimation
      ? heading?.split(/(\s+)/).filter((part) => part !== "")
      : heading?.split("")) || "";

  return (
    <div
      className={`heading-overflow-hidden ${
        onClick ? "pointer" : ""
      } ${className}`}
      onClick={onClick}
      style={{
        gap: gapBetweenChars,
        justifyContent: justifyContent,
      }}
    >
      {heading.map((char, i) => (
        <h1
          key={i}
          className={`${useSecondaryFont ? "secondary-font" : ""} ${
            useDarkColorFont ? "dark-color-font" : "light-color-font"
          }`}
          style={{
            animationDelay: `${
              i / (animationSpeed === "fast" ? 40 : 12) + animationDelay
            }s`,
            fontSize,
            fontWeight,
            paddingInline: char === " " ? fontSize / 5 || "2%" : 0,
          }}
        >
          {char}
        </h1>
      ))}
    </div>
  );
};

export default Heading;
