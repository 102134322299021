import { Route, Routes } from "react-router-dom";
import LandingPage from "./Components/LandingPage/LandingPage";
import { useEffect, useState } from "react";
import FontFaceObserver from "fontfaceobserver";
import Loader from "./Components/Common/Loader";
import NotFound from "./Components/NotFound/NotFound";
import { Helmet } from "react-helmet-async";
import "./App.css";

export const routes = {
  LANDING_PAGE: { path: "/", Component: LandingPage },
  NOT_FOUND: {
    path: "*",
    Component: NotFound,
  },
};

function App() {
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    const BalarmFontFamily = new FontFaceObserver("Balarm");
    const InterFontFamily = new FontFaceObserver("Inter");

    Promise.all([BalarmFontFamily.load(), InterFontFamily.load()])
      .then(() => {
        setFontsLoaded(true);
      })
      .catch((error) => {
        console.error("Error loading fonts", error);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>SCOUR</title>
        <meta
          name='description'
          content='At SCOUR Group, we specialize in custom home construction in Los Angeles, setting ourselves apart from traditional contractors by combining our expertise as both investors and builders. Our selective portfolio of cutting-edge, custom projects embodies the unique ethos of SCOUR Construction. With over 15 years of experience, SCOUR Group stands as a premier custom home builder and general construction firm, excelling in crafting distinguished custom homes, remodels, and multi-unit projects.'
        />
        <meta name='author' content='Muhammad Aqib Asif' />
        <meta
          name='keywords'
          content='scour group los angeles, los angeles, scour, scourgroup, scour group, scour construction, scour laboratory, scourconstruction, scourlaboratory, scour portfolio, scourgroup.com, scourconstruction.com, scourlaboratory.com, Muhammad Aqib Asif'
        />
        <link rel='canonical' href='/' />
      </Helmet>

      {!fontsLoaded ? (
        <Loader />
      ) : (
        <Routes>
          {Object.values(routes).map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<route.Component />}
            />
          ))}
        </Routes>
      )}
    </>
  );
}

export default App;
