import React from "react";
import Heading from "../Common/Heading";
import { useNavigate } from "react-router-dom";
import { routes } from "../../App";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className='laboratory-page'>
      <div className='logo-container'>
        <Heading heading='404' fontSize={"18vw"} />
        <Heading
          useSecondaryFont
          fontSize={24}
          fontWeight={400}
          animationDelay={0.3}
          heading='Page not found'
          animationSpeed='fast'
        />

        <button
          className='back-to-home-btn'
          onClick={() => navigate(routes.LANDING_PAGE.path)}
        >
          Back to Home
        </button>
      </div>

      <div className='landing-page-footer'>
        <p>© SCOUR 2024. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default NotFound;
