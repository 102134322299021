import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { routes } from "../../App";
import { ConLogoDark, LabLogoDark } from "../../Utils/images";
import Heading from "../Common/Heading";
import Loader from "../Common/Loader";

const LandingPage = () => {
  // const navigate = useNavigate();

  const [loading, setLoading] = useState(0);

  return loading === 2 ? (
    <Loader>
      {loading === 0 && (
        <img
          src={LabLogoDark}
          alt='scour'
          style={{ display: "none" }}
          onLoad={() => setLoading(1)}
        />
      )}
      {loading === 1 && (
        <img
          src={ConLogoDark}
          alt='scour'
          style={{ display: "none" }}
          onLoad={() => setLoading(2)}
        />
      )}
    </Loader>
  ) : (
    <div className='landing-page'>
      {/* <Header isLightMode /> */}

      <Heading heading='SCOUR' fontSize='18vw' useDarkColorFont />

      <div className='landing-page-content'>
        <div
          className='landing-page-images'
          onClick={() => window.open(process.env.REACT_APP_LABORATORY_URL)}
          // onClick={() => navigate(routes.LABORATORY.path)}
        >
          <img src={LabLogoDark} alt='scour laboratory' />
        </div>

        <div className='y-line' />

        <div
          className='landing-page-images'
          // onClick={() => navigate(routes.CONSTRUCTION.path)}
          onClick={() => window.open(process.env.REACT_APP_CONSTRUCTION_URL)}
        >
          <img src={ConLogoDark} alt='scour construction' />
        </div>
      </div>

      <div className='landing-page-footer light-mode'>
        <p>© SCOUR 2024. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default LandingPage;
