import React from "react";
import Heading from "./Heading";

const Loader = ({ children }) => {
  return (
    <div className='hero'>
      <Heading heading='Loading' fontSize={32} fontWeight={400} />
      {children}
    </div>
  );
};

export default Loader;
